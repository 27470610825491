import React from "react";
import './App.css';
import Homepage from './Component/Homepage'
import {Route, Switch,  BrowserRouter as Router, Link} from 'react-router-dom';
import AboutUs from "./Component/AboutUs";
import ContactUs from "./Component/ContactUs";
import logo from './assets/logo.png'
import Bottom from "./Component/Bottom";
import Product from "./Component/Product";
import $ from 'jquery';

class  App extends React.Component{

    componentDidMount() {
        window.onscroll = this.myFunction;
        $(".nav-link").on("click", function(){
            $(".a").removeClass("active");
            $(this).parent().addClass("active");
            if ($(window).width() < 669) {
                $('.navbar-toggle').click();
            }

        });
        let navbar = document.getElementById("navbar");
        navbar.classList.add("sticky");
    }

    myFunction =()=> {
        let navbar = document.getElementById("navbar");
        let sticky = navbar.offsetTop;
        if (window.pageYOffset >= sticky) {
            navbar.classList.add("sticky")
        } else {
            navbar.classList.add("sticky");
        }
    }

    render() {

        return (
            <Router>
                <nav className="navbar navbar-default header" role="navigation" id="navbar" >
                    <div className="navbar-header nav-head">
                        <button type="button" className="navbar-toggle"
                                data-toggle="collapse" data-target="#example-navbar-collapse">
                            <span className="sr-only">Toggle navigation</span>
                            <span className="icon-bar"></span>
                            <span className="icon-bar"></span>
                            <span className="icon-bar"></span>
                        </button>
                        <a className="navbar-brand company" href="/">
                            <img className="img-responsive logo" src={logo}/>
                            OUB ELECTRONIC
                        </a>
                    </div>
                    <div className="collapse navbar-collapse nav-opt" id="example-navbar-collapse">
                        <ul className="nav navbar-nav">
                            <li className="a active"><Link className="nav-link" to="/">HOME</Link></li>
                            <li className="a" ><Link className="nav-link" to="/product">PRODUCT</Link></li>
                            <li className="a"><Link className="nav-link" to="/about"> ABOUT US</Link></li>
                            <li className="a" ><Link className="nav-link" to="/contact"> CONTACT US</Link></li>
                        </ul>
                    </div>
                </nav>
                <Switch>

                    <Route path='/about' component={AboutUs}/>
                    <Route path='/contact' component={ContactUs}/>
                    <Route path='/product' component={Product}/>
                    <Route exact path='/' component={Homepage}/>
                </Switch>
                <Bottom/>
            </Router>
        );
    }
}






export default App;
