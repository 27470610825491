import React from 'react';

import '../CSS/Product.css'
import companyLed from '../assets/SingleLed.jpg';
import oubTube from '../assets/tubeOub.jpg'
import newImage from  '../assets/export_image_2.jpg'
import finaltube from    '../assets/finalTubeligh.png'
import Card from "./Card";
import image from "../assets/img7.jpg";
import blacklock from '../assets/black.jpg';
import safe1 from '../assets/safe1.jpg';
import safe2 from '../assets/safe2.jpg';
import lift from  '../assets/lift.jpg';
import Software from '../assets/software.jpg';
import oub1 from    '../assets/oub1.jpeg';
import oub2 from    '../assets/oub2.jpeg';
import pannelLight from '../assets/export_image_5.jpg';
import encoder from '../assets/hotel-locking-encoder-2-246x300.jpg';
import lock from '../assets/hotel-key-card-system-ss.jpg';
import query_card from '../assets/query_card_1.png';
import energy from '../assets/energy_saver_1.png';
import orbit_front from '../assets/orbita_front.jpg';
import orbita1 from '../assets/1-2411011451545a.jpg';
import image_alpha from '../assets/image_alpha.jpg';
import energy_saver from '../assets/energy_saver.jpeg';
import door_bell from '../assets/door_ball.jpg';
import wooden_key from '../assets/wooden_key_vard.jpeg';
import adel_1800_gold from '../assets/adel_1800_gold.jpg';
import axios from 'axios';
import URL from './Constnat'

export default  class Product extends React.Component{

    constructor(props) {
        super(props);
        this.state ={
            images: []
        }
    }

    componentDidMount(){
        this._getImages();
    }

    _getImages = () =>{
        
        axios.get(`${URL}/api/get-images`).then(response =>{
            console.log(response.data);
            this.setState({images: response.data.data})
        }).catch(error=>{
            console.log(error.message);
            alert(error.message);
        })
    }

    _renderImages = () => {
        return this.state.images.map(data => {
            return (
                <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12 column" key={data.id}> 
                    <Card
                        image={data.image_url}
                        name={data.lock_name}
                    />
                </div>
            );
        });
    }
    

    render() {
        return(
            <div className="product" >
                <div className="container-fluid slider" >
                    <div className='row product-head-row'>
                        <h2>Explore Our Product Catalogs</h2>
                    </div>
                </div>
                <br/>
                <br/>
                <br/>
                <div className="container-fluid card-container" >
                    <div className="row home-row lock">
                        <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12 column">
                            <Card image={blacklock}
                                  name={"ADEL 1800 BLACK"}
                                  price={"99"}
                                  prmotionalPrice={"200"}
                                  percentOff={"12%"}
                            />

                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12 column">
                            <Card image={lock}
                                  name={"ADEL 1800"}
                                  price={"900"}
                                  prmotionalPrice={"1200"}
                                  percentOff={"25%"}

                            />
                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12 column">
                            <Card image={image_alpha}
                                  name={"A6L ALPHA LOCK"}
                                  price={"900"}
                                  prmotionalPrice={"1300"}
                                  percentOff={"31%"}
                            />
                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12 column">
                            <Card image={adel_1800_gold}
                                  name={"E3041 LOCK"}
                                  price={"599"}
                                  prmotionalPrice={"899"}
                                  percentOff={"33%"}
                            />
                        </div>
                        {this._renderImages()}
                    </div>
                </div>
                {/* <div className="container-fluid card-container" >
                    <div className="row home-row">
                        {this._renderImages()}
                    </div>
                </div> */}
            </div>
        )
    }


}


