import React from 'react';
import "../CSS/AboutUs.css"
export default class AboutUs extends React.Component{

    constructor(props) {
        super(props);
    }

    render() {
        return(
            <section className="text-center about">
                <h1>About US</h1>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-sm-6 col-ex-12 about-item wow lightSpeedIn" data-wow-offset="200">
                            <img src="https://png.pngtree.com/png-vector/20190321/ourmid/pngtree-vector-users-icon-png-image_856952.jpg"/>
                            <h2>Chandan Mishra</h2>
                            <p><span className="glyphicon glyphicon-earphone"><i>9311559608</i> </span></p>
                            <p><span className="glyphicon glyphicon-envelope"><i>chandan@oubelectronics.in</i> </span> </p>
                            <p>Founder & CEO</p>

                        </div>
                        <div className="clearfix visible-md-block visible-sm-block"></div>
                        <div className="col-lg-6 col-sm-6 col-ex-12 about-item wow lightSpeedIn" data-wow-offset="200">
                            <img src="https://png.pngtree.com/png-vector/20190321/ourmid/pngtree-vector-users-icon-png-image_856952.jpg" />
                            <h2>Aditya Jha</h2>
                            <p><span className="glyphicon glyphicon-earphone"><i>9311559608</i> </span></p>
                            <p><span className="glyphicon glyphicon-envelope"><i> info@oubelectronics.in</i> </span> </p>
                            <p>Co-founder</p>
                        </div>

                    </div>

                </div>
            </section>
        )
    }


}