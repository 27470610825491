import React from 'react';
import '../CSS/Card.css'


export default class Card extends React.Component{

    constructor(props) {
        super(props);
        this.state ={

        }
    }

    render() {
        return(
            <div style={{background:"#fff"}}>
                <div className="card item-card card-block">
                    <img src={this.props.image} className="img-responsive card-img"
                         alt="Photo of sunset"/>
                    <div className="arrival-info">
                        <h4 className="item-card-title mt-3 mb-3">{this.props.name}</h4>
                        {/*<p className="card-text" >Rs {this.props.price}</p>*/}
                        {/*<span className="card-text pric1"><del>Rs {this.props.prmotionalPrice}</del></span>*/}
                        {/*<span className="card-text disc">[{this.props.percentOff} Off]</span>*/}
                    </div>
                </div>
            </div>
        )
    }
}

